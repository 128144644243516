/* eslint no-undef: 0 */
'use strict';

module.exports = {
    digitalDataFormErrors: function () {
        $('body').on('formFieldError', function (customEvent, errorData) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: errorData.type,
                    errorMessage: errorData.errorMessage
                });
            }
        });
    }
};
